import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore
} from 'react';

import { matchPath, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  ResourceType,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';
import Button from 'ecto-common/lib/Button/Button';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import HelpPaths from 'ecto-common/help/tocKeys';

import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import CreateLocationDialog from 'js/components/EditLocation/CreateLocationDialog';
import LocationForm, {
  LocationFormData
} from 'js/components/EditLocation/LocationForm';
import { NodeTraitIds, NodeTypes } from 'ecto-common/lib/utils/constants';
import { EditMeteorologyPointForNode } from './EditMeteorologyPointDialog';
import AddLogEntryDialog from 'js/components/EditLocation/AddLogEntryDialog';
import {
  patchNodes,
  updateNodeTreeIncrementallyFromDelete
} from 'js/modules/provisioningCommon/provisioningCommon';
import { getLocationRoute, LocationRoute } from 'js/utils/routeConstants';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { useAdminDispatch, useAdminSelector } from 'js/reducers/storeAdmin';
import APIGen, {
  NodePropertyResponseModel,
  NodePropertyValueResponseModel,
  NodeV2ResponseModel,
  PropertyValidationType
} from 'ecto-common/lib/API/APIGen';
import EditIntegrationPointsForNode from '../Integrations/EditIntegrationPointsForNode';
import { AdminDispatch } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePromptFunction } from 'ecto-common/lib/hooks/useBlockerListener';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import { adminHomeUrlBuilder } from 'js/utils/linkUtil';
import {
  InstantiateBuildingActions,
  InstantiatingTemplateState
} from 'js/modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import InstantiateEmptyBuilding from 'js/components/InstantiateEmptyBuilding/InstantiateEmptyBuilding';
import SelectDashboardRelationDialog from '../Dashboards/SelectDashboardRelationDialog';
import SelectFilesDialog from 'ecto-common/lib/SelectFilesDialog/SelectFilesDialog';
import {
  nodeIsBuilding,
  nodeIsEquipment,
  nodeIsSite,
  resetAllNodeCaches,
  useNodeChildren,
  useNodeTraits
} from 'ecto-common/lib/hooks/useCurrentNode';
import UUID from 'uuidjs';
import { useStore } from 'zustand';

import { useParams } from 'react-router-dom';

import {
  EnergyManagerSubpage,
  EquipmentSubpage,
  SpaceSubpage
} from 'js/utils/LocationEndpoints';
import EditEquipmentSignals from 'js/components/ManageEquipment/EditEquipment/EditEquipmentSignals';
import ProvisionDevice from 'js/components/ManageEquipment/ProvisionDevice/ProvisionDevice';
import EditEnergyManager from 'js/components/ManageEquipment/EditEnergyManager/EditEnergyManager';
import DeployEnergyManager from 'js/components/ManageEquipment/DeployEnergyManager/DeployEnergyManager';
import EditEquipmentTools from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { nodeIsEnergyManager } from 'ecto-common/lib/hooks/useCurrentNode';
import styles from './LocationForm.module.css';
import ManageComfortTools from 'js/components/EditLocation/Tools/ManageComfortTools';
import ManageTemperatureImpact from 'js/components/EditLocation/Tools/ManageTemperatureImpact';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import NotificationsDialog from 'js/components/Notifications/NotificationsDialog';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import { NodeTreeStoreContext } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

const FORM_DATA_INITIAL_STATE: LocationFormData = {
  parentId: null,
  name: '',
  description: '',
  street: '',
  nodeTraits: [
    {
      nodeTraitId: NodeTraitIds.SITE,
      isLocked: true
    }
  ],
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG
};

// NOTE: We don't patch the node tree in this promise since that can lead to the component
// rendering the editor being unmounted before the onSuccess callback can be called.
const deleteLocationPromise = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  dispatch: AdminDispatch
) => {
  return APIGen.AdminNodes.deleteNode
    .promise(contextSettings, { nodeId }, null)
    .then(() => APIGen.AdminNodes.getNodeTags.promise(contextSettings, null))
    .then((nodeTags: string[]) => {
      dispatch(setNodeTags(nodeTags));
    });
};

interface EditLocationProps {
  onTitleChanged: (title: string[]) => void;
  selectedLocation: NodeV2ResponseModel;
  isLoading: boolean;
}

const FakeRootNode: NodeV2ResponseModel = {
  nodeId: ROOT_NODE_ID,
  nodeTraits: [
    {
      nodeTraitId: NodeTraitIds.SITE,
      isLocked: true
    }
  ],
  parentId: null,
  name: '',
  path: '',
  hasChildren: true
};

function validateRegexp(
  property: NodePropertyResponseModel,
  propertyValue: string
) {
  try {
    return new RegExp(property.validationData.regexValue).test(propertyValue);
  } catch (e) {
    return true; // Invalid regexp, no way to validate
  }
}

const EditLocation = ({
  onTitleChanged,
  selectedLocation: selectedLocationArg,
  isLoading
}: EditLocationProps) => {
  const nodeTreeStore = useContext(NodeTreeStoreContext);

  const addNodes = useStore(nodeTreeStore, (store) => store.addNodes);
  const clearAllNodes = useStore(nodeTreeStore, (store) => store.clearAllNodes);

  const removeNodesWithIds = useStore(
    nodeTreeStore,
    (store) => store.removeNodesWithIds
  );
  const removeNode = useStore(nodeTreeStore, (store) => store.removeNode);

  const dispatch = useAdminDispatch();
  const navigate = useNavigate();
  const [isShowingNodeForm, showNodeForm, hideNodeForm] =
    useSimpleDialogState();
  const [isShowingSelectFiles, showSelectFiles, hideSelectFiles] =
    useSimpleDialogState();

  const creatRootNodeForm = useAdminSelector(
    (state) => state.createNodeForm.createRootNode
  );

  const selectedLocation = selectedLocationArg ?? FakeRootNode;
  const nodePropertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery({});
  const allNodeTraitsQuery = useNodeTraits();
  const nodePropertyValuesQuery = APIGen.NodesV2.getNodePropertyValues.useQuery(
    {
      nodeId: selectedLocation?.nodeId
    },
    {
      enabled: !!selectedLocation && selectedLocation.nodeId !== ROOT_NODE_ID
    }
  );

  const [nodePropertyValues, setNodePropertyValues] = useState<
    Record<string, string>
  >({});

  const [prevNodeId, setPrevNodeId] = useState(selectedLocation?.nodeId);

  if (prevNodeId !== selectedLocation?.nodeId) {
    setPrevNodeId(selectedLocation?.nodeId);
    setNodePropertyValues({});
  }

  const allNodePropertyValues = useMemo(() => {
    return {
      ..._.mapValues(
        _.keyBy(nodePropertyValuesQuery.data, (x) => x.nodePropertyId),
        (entry) => entry.value
      ),
      ...nodePropertyValues
    };
  }, [nodePropertyValues, nodePropertyValuesQuery.data]);

  const allPropertyIds = useMemo(() => {
    return _.uniq(
      _.flatMap(
        _.filter(allNodeTraitsQuery.data?.items, ({ id }) =>
          _.some(
            selectedLocation?.nodeTraits,
            (trait) => trait.nodeTraitId === id
          )
        ),
        (trait) =>
          trait.propertyTraitRelationModels.map((x) => x.nodePropertyId)
      )
    );
  }, [allNodeTraitsQuery.data, selectedLocation?.nodeTraits]);

  const nodeProperties = useMemo(() => {
    return _.compact(
      allPropertyIds.map((id) =>
        _.find(nodePropertiesQuery.data?.items, { id })
      )
    );
  }, [allPropertyIds, nodePropertiesQuery.data]);

  const { tenantId } = useContext(TenantContext);

  const [hasChanges, setHasChanges] = useState(false);
  const [formData, setFormData] = useState<LocationFormData>(
    FORM_DATA_INITIAL_STATE
  );
  const [showConfirmDelete, onShowConfirmDelete, onHideConfirmDelete] =
    useDialogState('show-confirm-delete');

  const [
    showEditDashboardCollection,
    onShowEditDashboardCollection,
    onHideEditDashboardCollection
  ] = useDialogState('show-edit-dashboard-collection');

  const [showEditProcessMap, onShowEditProcessMap, onHideEditProcessMap] =
    useDialogState('show-edit-process-map');

  const [
    showEditNotifications,
    onShowEditNotifications,
    onHideEditNotifications
  ] = useDialogState('show-edit-notifications');

  const [showLogEntryForm, onShowLogEntry, onHideLogEntry] =
    useDialogState('show-log-entry');

  useEffect(() => {
    setFormData({
      ...FORM_DATA_INITIAL_STATE,
      parentId: selectedLocation.parentId,
      name: selectedLocation.name,
      description: selectedLocation.description,
      street: selectedLocation.street,
      nodeTraits: selectedLocation.nodeTraits,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude
    });
    setHasChanges(false);
  }, [selectedLocation]);

  const formDataChanged = useCallback((newState: Partial<LocationFormData>) => {
    setHasChanges(true);
    setFormData((prevState) => ({
      ...prevState,
      ...newState
    }));
  }, []);

  const annotatedLocationObject = useCallback(() => {
    const { nodeId, name, longitude, latitude, street, path, hasChildren } =
      selectedLocation;

    const parentId = formData.parentId?.startsWith(ROOT_NODE_ID)
      ? null
      : formData.parentId;

    const data = {
      nodeId,
      name,
      longitude,
      latitude,
      street,
      hasChildren,
      path,
      ...formData,
      parentId
    };

    return data;
  }, [formData, selectedLocation]);

  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const saveNodePropertiesMutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodePropertyValues.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey:
          APIGen.NodesV2.getNodePropertyValuesWithProperties.path(
            contextSettings
          )
      });
    },
    meta: {
      errorString: T.admin.editlocation.savepropertieserror
    },
    mutationFn: async ({
      properties
    }: {
      properties: NodePropertyValueResponseModel[];
    }) => {
      await APIGen.NodesV2.addOrUpdateNodePropertyValues.promise(
        contextSettings,
        {
          propertyValues: properties
        },
        null
      );
    }
  });

  const updateNodesMutation = APIGen.NodesV2.addOrUpdateNodes.useMutation({
    onSuccess: (_res, args) => {
      const updatedNode = _.head(args.nodes);

      if (updatedNode.parentId !== selectedLocation.parentId) {
        resetAllNodeCaches(queryClient, contextSettings);
        clearAllNodes();
      } else {
        patchNodes(contextSettings, args.nodes, queryClient);
        addNodes(args.nodes);
      }

      toastStore.addSuccessToast(
        nodeIsBuilding(updatedNode)
          ? T.admin.editbuilding.updated.building
          : T.admin.editsite.updated.site
      );
      setHasChanges(false);
    },

    onError: (error, args) => {
      let failText;
      const extraInfoText = error.error?.detail ?? null;

      if (
        error.response?.status === HttpStatus.CONFLICT &&
        extraInfoText == null
      ) {
        failText = T.common.conflicterror;
      } else {
        failText = nodeIsBuilding(args.nodes[0])
          ? T.admin.editbuilding.update.failed
          : T.admin.editsite.update.failed;
      }

      toastStore.addErrorToast(
        _.compact([failText, extraInfoText]).join(' - ')
      );
    }
  });

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  const deleteLocationMutation = useMutation({
    mutationFn: (node: NodeV2ResponseModel) => {
      return deleteLocationPromise(contextSettings, node.nodeId, dispatch);
    },

    onSuccess: (_unused, deletedLocation) => {
      toastStore.addSuccessToast(T.admin.requests.deletelocation.success);
      removeNode(deletedLocation.nodeId);
      updateNodeTreeIncrementallyFromDelete(
        deletedLocation.nodeId,
        deletedLocation.parentId,
        queryClient,
        removeNodesWithIds,
        contextSettings
      );
      onHideConfirmDelete();

      if (selectedLocation.parentId == null) {
        const otherRootLevelNode = _.find(
          rootLevelNodes,
          (x) => x.nodeId !== selectedLocation.nodeId
        );

        if (otherRootLevelNode) {
          navigate(getLocationRoute(tenantId, otherRootLevelNode.nodeId), {
            replace: true
          });
        } else {
          navigate(getLocationRoute(tenantId, ROOT_NODE_ID), {
            replace: true
          });
        }
      } else {
        navigate(getLocationRoute(tenantId, selectedLocation.parentId), {
          replace: true
        });
      }
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.requests.deletelocation.failure);
    }
  });

  const hasUnsavedProperties = _.some(allPropertyIds, (propertyId) => {
    const existingValue = _.find(
      nodePropertyValuesQuery.data,
      (x) => x.nodePropertyId === propertyId
    );

    return (
      nodePropertyValues[propertyId] !== undefined &&
      (existingValue == null ||
        nodePropertyValues[propertyId] !== existingValue.value)
    );
  });

  const propertyErrors = useMemo(() => {
    const ret: Record<string, boolean> = {};

    for (const property of nodeProperties) {
      const propertyValue = allNodePropertyValues[property.id];

      if (property.validationType === PropertyValidationType.EnumList) {
        if (
          propertyValue != null &&
          !property.validationData.enumValues.includes(propertyValue)
        ) {
          ret[property.id] = true;
        }
      } else if (property.validationType === PropertyValidationType.Regex) {
        if (propertyValue != null && !validateRegexp(property, propertyValue)) {
          ret[property.id] = true;
        }
      }
    }

    return ret;
  }, [allNodePropertyValues, nodeProperties]);

  const [
    showingPropertyErrorsDialog,
    showPropertyErrorsDialog,
    hidePropertyErrorsDialog
  ] = useSimpleDialogState(false);

  const performUpdate = useCallback(() => {
    if (!_.isEmpty(propertyErrors)) {
      showPropertyErrorsDialog();
      return;
    }

    if (hasChanges) {
      const location = annotatedLocationObject();

      updateNodesMutation.mutate({
        nodes: [location]
      });
    }

    const valueDtos: NodePropertyValueResponseModel[] = _.compact(
      _.map(allPropertyIds, (propertyId) => {
        const existingValue = _.find(
          nodePropertyValuesQuery.data,
          (x) => x.nodePropertyId === propertyId
        );

        if (
          nodePropertyValues[propertyId] === undefined ||
          (existingValue != null &&
            nodePropertyValues[propertyId] === existingValue?.value)
        ) {
          return null;
        }

        const value = nodePropertyValues[propertyId] ?? '';

        if (existingValue) {
          return {
            ...existingValue,
            value
          };
        }

        return {
          id: UUID.generate(),
          nodeId: selectedLocation.nodeId,
          nodePropertyId: propertyId,
          value
        };
      })
    );

    if (valueDtos.length > 0) {
      saveNodePropertiesMutation.mutate({
        properties: valueDtos
      });
    }
  }, [
    allPropertyIds,
    annotatedLocationObject,
    hasChanges,
    nodePropertyValues,
    nodePropertyValuesQuery.data,
    propertyErrors,
    saveNodePropertiesMutation,
    selectedLocation.nodeId,
    showPropertyErrorsDialog,
    updateNodesMutation
  ]);

  const performDelete = useCallback(() => {
    const location = annotatedLocationObject();
    deleteLocationMutation.mutate(location);
  }, [annotatedLocationObject, deleteLocationMutation]);

  const newType = useCallback(
    (type: string, createRootNode: boolean = false) => {
      dispatch(CreateNodeActions.resetForm());
      dispatch(CreateNodeActions.setType(type, createRootNode));
      dispatch(
        CreateNodeActions.setCoordinates(
          selectedLocation.latitude || DEFAULT_LAT,
          selectedLocation.longitude || DEFAULT_LNG
        )
      );
      showNodeForm();
    },
    [
      dispatch,
      selectedLocation.latitude,
      selectedLocation.longitude,
      showNodeForm
    ]
  );

  const onNewTypeSite = useCallback(() => newType(NodeTypes.SITE), [newType]);
  const onNewRootNode = useCallback(
    () => newType(NodeTypes.SITE, true),
    [newType]
  );

  const onConfirmDelete = useCallback(() => {
    if (showConfirmDelete) {
      performDelete();
    } else {
      onShowConfirmDelete();
    }
  }, [showConfirmDelete, performDelete, onShowConfirmDelete]);

  const isRootNode = selectedLocation.nodeId.startsWith(ROOT_NODE_ID);
  const isMutating =
    updateNodesMutation.isPending || saveNodePropertiesMutation.isPending;
  const isLoadingOrMutating = isMutating || isLoading;

  const isEquipment = nodeIsEquipment(selectedLocation);

  const [
    showingInstantiateEmptyBuildingDialog,
    showInstantiateEmptyBuildingDialog,
    hideInstantiateEmptyBuildingDialog
  ] = useSimpleDialogState();

  const onInstantiateBuildingTemplate = useCallback(() => {
    dispatch(InstantiateBuildingActions.setLocation(selectedLocation));
    showInstantiateEmptyBuildingDialog();
  }, [dispatch, selectedLocation, showInstantiateEmptyBuildingDialog]);

  const toolbarItems = useMemo(
    () => [
      <ToolbarFlexibleSpace key="space" />,
      selectedLocation.parentId == null && (
        <ToolbarItem key="add-root-node">
          <Button onClick={onNewRootNode} disabled={isLoadingOrMutating}>
            <Icons.Add />
            {T.admin.editlocation.addnewrootnode}
          </Button>
        </ToolbarItem>
      ),
      !isRootNode && (
        <ToolbarItem key="add-node">
          <Button onClick={onNewTypeSite} disabled={isLoadingOrMutating}>
            <Icons.Add /> {T.admin.createlocation.addnode}
          </Button>
        </ToolbarItem>
      ),
      !isEquipment && (
        <ToolbarItem key="instantiate-building-template">
          <Button
            onClick={onInstantiateBuildingTemplate}
            disabled={isLoadingOrMutating}
          >
            <Icons.File /> {T.admin.equipment.addfromtemplate.title}
          </Button>
        </ToolbarItem>
      ),
      !isRootNode && (
        <ToolbarItem key="add-log-entry">
          <Button onClick={onShowLogEntry} disabled={isLoadingOrMutating}>
            <Icons.File />
            {T.admin.editlocation.addlogentry}
          </Button>
        </ToolbarItem>
      )
    ],
    [
      selectedLocation.parentId,
      onNewRootNode,
      isLoadingOrMutating,
      isRootNode,
      onNewTypeSite,
      isEquipment,
      onInstantiateBuildingTemplate,
      onShowLogEntry
    ]
  );

  const params = useParams<NodeParams>();

  usePromptFunction(
    useCallback(
      ({ nextLocation }) => {
        const newParams = matchPath<NodeParams, typeof LocationRoute.path>(
          LocationRoute.path,
          nextLocation.pathname
        )?.params;
        return newParams?.nodeId !== params.nodeId ||
          newParams.page !== params.page
          ? T.admin.form.unsavedstate
          : null;
      },
      [params.nodeId, params.page]
    ),
    hasChanges || hasUnsavedProperties
  );

  usePageTitleCallback({
    mainTitle: T.admin.tabs.locations,
    subTitle: '',
    onTitleChanged
  });

  type SegmentItem = OptionWithIcon & {
    pageTag: (equipment: NodeV2ResponseModel) => React.ReactNode;
  };

  const templateState = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.templateState
  );

  const localTemplateStateRef = useRef(undefined);

  const allNodesMap = useStore(nodeTreeStore, (store) => store.allNodesMap);

  // Find the first non-equipment parent, use that to load chidren,
  // and from that get the EM. Assume EM has to be a direct child of a non-equipment parent.
  let emParentNode = selectedLocation;

  const visitedParentNodes: Record<string, boolean> = {};

  while (emParentNode && nodeIsEquipment(emParentNode)) {
    if (visitedParentNodes[emParentNode.nodeId]) {
      break;
    } else {
      visitedParentNodes[emParentNode.nodeId] = true;
    }

    emParentNode = allNodesMap[emParentNode.parentId];
  }

  const equipmentChildrenQuery = useNodeChildren(
    _.compact([emParentNode?.nodeId])
  );

  const energyManagers = useMemo(() => {
    return _.filter(equipmentChildrenQuery.nodeChildren, (equipment) =>
      nodeIsEnergyManager(equipment)
    );
  }, [equipmentChildrenQuery.nodeChildren]);

  useEffect(() => {
    if (templateState !== localTemplateStateRef.current) {
      if (templateState === InstantiatingTemplateState.ERROR) {
        if (
          localTemplateStateRef.current ===
          InstantiatingTemplateState.INSTANTIATING_TEMPLATE
        ) {
          toastStore.addErrorToast(T.admin.equipment.addfromtemplate.error);
        } else if (
          localTemplateStateRef.current ===
          InstantiatingTemplateState.ADDING_CONNECTION
        ) {
          toastStore.addErrorToast(
            T.admin.equipment.addfromtemplate.errorconnection
          );
        } else if (localTemplateStateRef.current === null) {
          toastStore.addErrorToast(T.common.unknownerror);
        } else {
          toastStore.addErrorToast(
            T.admin.equipment.addfromtemplate.errorafterinit
          );
        }
      }

      localTemplateStateRef.current = templateState;
    }
  }, [templateState]);

  const { tenantResources } = useContext(TenantContext);

  const changePage = useCallback(
    (newSubpage: string) => {
      navigate(getEquipmentPageUrl(tenantId, params.nodeId, newSubpage));
    },
    [navigate, params.nodeId, tenantId]
  );

  const changeSubpage = useCallback(
    (newSubpage: string) => {
      changePage(newSubpage);
    },
    [changePage]
  );

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const segmentItems = useMemo(() => {
    let items: SegmentItem[] = [
      {
        label: T.admin.editlocation.section.nodedetails,
        icon: <Icons.Site />,
        value: EquipmentSubpage.NODE,
        pageTag: (node) => {
          return (
            <LocationForm
              selectedLocation={node}
              isVirtualRootNode={selectedLocationArg == null}
              onEditNotifications={onShowEditNotifications}
              onEditProcessMaps={onShowEditProcessMap}
              onEditDashboards={onShowEditDashboardCollection}
              onEditFiles={showSelectFiles}
              formData={formData}
              onFormDataChanged={formDataChanged}
              nodeProperties={nodeProperties}
              nodePropertyValues={allNodePropertyValues}
              isLoadingNodePropertyValues={nodePropertyValuesQuery.isLoading}
              setNodePropertyValues={setNodePropertyValues}
              propertyErrors={propertyErrors}
              onSaveNode={performUpdate}
              onDeleteNode={onConfirmDelete}
              saveButtonDisabled={
                (!hasChanges && !hasUnsavedProperties) || isMutating
              }
            />
          );
        }
      }
    ];

    if (nodeIsEquipment(selectedLocation)) {
      items = [
        ...items,
        {
          label: T.admin.equipment.section.signals,
          icon: <Icons.Signal />,
          value: EquipmentSubpage.SIGNALS,
          pageTag: (equipment) => (
            <EditEquipmentSignals
              key={equipment.nodeId}
              equipment={equipment}
            />
          )
        }
      ];
    }

    if (!nodeIsEquipment(selectedLocation)) {
      items = _.compact([
        ...items,
        {
          label: T.admin.editlocation.tools.comfort.title,
          icon: <Icons.Building />,
          value: SpaceSubpage.COMFORT,
          pageTag: (node: NodeV2ResponseModel) => (
            <ManageComfortTools nodeId={node.nodeId} />
          )
        },
        {
          label: T.admin.editlocation.tools.temperatureimpact,
          icon: <Icons.Temperature />,
          value: SpaceSubpage.TEMPERATURE_IMPACT,
          pageTag: (node: NodeV2ResponseModel) => (
            <ManageTemperatureImpact nodeId={node.nodeId} />
          )
        },
        hasAccessToResource(ResourceType.USER_MANAGEMENT, tenantResources) && {
          label: T.admin.editlocation.section.meteorology,
          icon: <Icons.Weather />,
          value: SpaceSubpage.METEOROLOGY,
          pageTag: (node: NodeV2ResponseModel) => (
            <EditMeteorologyPointForNode selectedLocation={node} />
          )
        },
        featureFlagState.integrations && {
          label: T.admin.editlocation.section.integrations,
          icon: <Icons.Data />,
          value: SpaceSubpage.INTEGRATION_POINTS,
          pageTag: (node: NodeV2ResponseModel) => (
            <EditIntegrationPointsForNode nodeId={node.nodeId} />
          )
        }
      ]);
    }

    if (nodeIsEnergyManager(selectedLocation)) {
      items = [
        ...items,
        ...[
          {
            label: T.admin.equipment.section.iotdevice,
            icon: <Icons.Settings />,
            value: EnergyManagerSubpage.IOTDEVICE,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <ProvisionDevice equipment={equipment} />
            )
          },
          {
            label: T.admin.equipment.section.connections,
            icon: <Icons.EnergyManager />,
            value: EnergyManagerSubpage.CONNECTIONS,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <EditEnergyManager equipment={equipment} />
            )
          },
          {
            label: T.admin.equipment.section.deployment,
            icon: <Icons.Connect />,
            value: EnergyManagerSubpage.DEPLOYMENT,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <DeployEnergyManager equipment={equipment} />
            )
          }
        ]
      ];
    } else {
      items.push({
        label: T.admin.equipment.section.tools,
        icon: <Icons.Tool />,
        value: EquipmentSubpage.TOOLS,
        pageTag: (equipment) => <EditEquipmentTools equipment={equipment} />
      });
    }

    return items;
  }, [
    allNodePropertyValues,
    featureFlagState.integrations,
    formData,
    formDataChanged,
    hasChanges,
    hasUnsavedProperties,
    isMutating,
    nodeProperties,
    nodePropertyValuesQuery.isLoading,
    onConfirmDelete,
    onShowEditDashboardCollection,
    onShowEditNotifications,
    onShowEditProcessMap,
    performUpdate,
    propertyErrors,
    selectedLocation,
    selectedLocationArg,
    showSelectFiles,
    tenantResources
  ]);

  const page =
    segmentItems.find((segmentItem) => segmentItem.value === params.itemId)
      ?.value ?? segmentItems[0].value;

  const selectedOption = segmentItems.find((item) => item.value === page);

  const nodeComponent = useMemo(
    () => selectedOption.pageTag(selectedLocation),
    [selectedLocation, selectedOption]
  );

  const createLocationDialogRoot = creatRootNodeForm
    ? FakeRootNode
    : selectedLocation;

  let deleteTitle: string = null;

  if (nodeIsSite(selectedLocation)) {
    deleteTitle = T.admin.editsite.delete.title;
  } else if (nodeIsBuilding(selectedLocation)) {
    deleteTitle = T.admin.editbuilding.delete.title;
  } else if (nodeIsEquipment(selectedLocation)) {
    deleteTitle = T.admin.editequipment.delete.title;
  }

  return (
    <>
      <ToolbarContentPage
        title={T.admin.tabs.locations}
        toolbarItems={toolbarItems}
        helpPath={HelpPaths.docs.admin.manage.locations.locations}
        selectEquipment
        urlBuilder={adminHomeUrlBuilder}
      >
        <LoadingContainer isLoading={isLoadingOrMutating} showSpinner>
          {selectedLocation.nodeId !== ROOT_NODE_ID && (
            <div className={styles.subpageSelector}>
              <CollapsingSegmentControlPicker
                options={segmentItems}
                value={selectedOption}
                onChangeValue={(value) => changeSubpage(value.value)}
              />
            </div>
          )}

          {selectedLocation.nodeId !== ROOT_NODE_ID && nodeComponent}
        </LoadingContainer>
      </ToolbarContentPage>

      <ActionModal
        compact
        onModalClose={onHideConfirmDelete}
        isOpen={showConfirmDelete}
        title={deleteTitle}
        onConfirmClick={performDelete}
        isLoading={deleteLocationMutation.isPending}
      >
        {nodeIsBuilding(selectedLocation)
          ? T.admin.editbuilding.deletelocation.text
          : T.admin.editsite.deletelocation.text}
      </ActionModal>

      <CreateLocationDialog
        parentLocation={createLocationDialogRoot}
        onModalClose={hideNodeForm}
        isOpen={isShowingNodeForm}
        devices={energyManagers}
      />

      <AddLogEntryDialog
        isOpen={showLogEntryForm}
        onModalClose={onHideLogEntry}
        location={selectedLocation}
      />

      <ActionModal
        isOpen={showingPropertyErrorsDialog}
        title={T.admin.editlocation.invalidproperties.title}
        onModalClose={hidePropertyErrorsDialog}
        onConfirmClick={hidePropertyErrorsDialog}
        disableCancel
        actionText={T.common.ok}
        headerIcon={Icons.Error}
      >
        {T.admin.editlocation.invalidproperties.text}
      </ActionModal>

      <SelectDashboardRelationDialog
        nodeId={selectedLocation.nodeId}
        isOpen={showEditDashboardCollection}
        onModalClose={onHideEditDashboardCollection}
      />

      <NotificationsDialog
        nodeId={selectedLocation.nodeId}
        isOpen={showEditNotifications}
        onModalClose={onHideEditNotifications}
      />

      <SelectFilesDialog
        onModalClose={hideSelectFiles}
        isOpen={isShowingSelectFiles}
        nodeId={selectedLocation.nodeId}
        canEditFiles
      />
      <SelectProcessMapDialog
        onModalClose={onHideEditProcessMap}
        isOpen={showEditProcessMap}
        nodeId={selectedLocation.nodeId}
      />

      <InstantiateEmptyBuilding
        location={selectedLocation}
        isOpen={showingInstantiateEmptyBuildingDialog}
        onModalClose={hideInstantiateEmptyBuildingDialog}
      />
    </>
  );
};

export default EditLocation;
